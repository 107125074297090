import { combineReducers } from 'redux'
import { LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS, LOAD_SMS_BALANCE_SUCCESS, LOAD_SMS_PROVIDER_SUCCESS, RESET_SMS_BALANCE } from './types'

// SMS | GET PROVIDERS
export interface ProvidersState {
  Name: string
  BaseUrl: string
  LoginPath: string
}

interface LoadListOfSmsProvidersSuccess {
  type: typeof LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS
  payload: {
    data: {
      Data: ProvidersListState
    }
  }
}
type ProvidersActions = LoadListOfSmsProvidersSuccess
type ProvidersListState = ReadonlyArray<ProvidersState>

const defaultProvidersState: ProvidersState[] = []

function providers(state: ProvidersListState = defaultProvidersState, action: ProvidersActions): ProvidersListState {
  switch (action.type) {
    case LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS: {
      return action.payload.data.Data
    }
    default:
      return state
  }
}

// SMS | GET PROVIDER
interface ConfiguredAccounts {
  AccountName: string
  Provider: string
  Credentials: {
    Origin: string
    APIKey: string
  }
}
export interface ProviderState {
  DefaultAccount?: string
  ConfiguredAccounts?: ConfiguredAccounts[]
}
interface LoadSmsProviderSuccess {
  type: typeof LOAD_SMS_PROVIDER_SUCCESS
  payload: {
    data: {
      Data: ProviderState
    }
  }
}
type ProviderActions = LoadSmsProviderSuccess

const defaultProviderState: ProviderState = {
  DefaultAccount: '',
  ConfiguredAccounts: []
}
function provider(state: ProviderState = defaultProviderState, action: ProviderActions): ProviderState {
  switch (action.type) {
    case LOAD_SMS_PROVIDER_SUCCESS: {
      return action.payload.data.Data
    }
    default:
      return state
  }
}

// SMS | GET BALANCE
type PaymentType = 'credits' | 'euros' | 'dollars' | 'pounds'
type BalancePayment = 'postpaid' | 'prepaid'

interface BalanceState {
  Amount: number
  Type?: PaymentType
  Payment?: BalancePayment
}

interface LoadSmsBalanceSuccess {
  type: typeof LOAD_SMS_BALANCE_SUCCESS
  payload: {
    data: {
      Data: BalanceState
    }
  }
}

interface ResetSmsBalanceSuccess {
  type: typeof RESET_SMS_BALANCE
}

type BalanceActions = LoadSmsBalanceSuccess | ResetSmsBalanceSuccess

const defaultBalanceState: BalanceState = {
  Amount: 0
}

function balance(state: BalanceState = defaultBalanceState, action: BalanceActions): BalanceState {
  switch (action.type) {
    case LOAD_SMS_BALANCE_SUCCESS: {
      return action.payload.data.Data
    }
    case RESET_SMS_BALANCE: {
      return defaultBalanceState
    }
    default:
      return state
  }
}

const sms = combineReducers({
  providers,
  provider,
  balance
})

export default sms
